import { getFromStorage } from "localStorageService";

const mobileOrMacOSX_10_14 = ( /Mobi/.test(navigator.userAgent) ||
    /10_14/.test(navigator.userAgent) || /10_15/.test(navigator.userAgent) ||
    /10_16/.test(navigator.userAgent) || /10_17/.test(navigator.userAgent) ||
    /10_18/.test(navigator.userAgent) || /10_19/.test(navigator.userAgent) 
);
const isBot = /(bot.html|bots|Bot|Applebot|Googlebot|Cocolyzebot|page-preview|BW|Dataprovider.com)/.test(navigator.userAgent);
const isTestDomain = /(local|test|j.)/.test(window.location.hostname);
const isHashEmail = /(email)/.test(window.location.hash);
const isHashOverride = /(override)/.test(window.location.hash);
const emojiSDG = mobileOrMacOSX_10_14 ? '🇺🇳' : '🌍';

const dbStorageKey = getFromStorage('db');
const dbUrlKey = /(xxx|local|j.alpha)/.test(window.location.hostname) ? 'dev' :
    /(esg|x.)/.test(window.location.hostname) ? 'esg' :
    /(sdg|locxx)/.test(window.location.hostname) ? 'sdg' :
    /(pope|frances)/.test(window.location.hostname) ? 'pope' :
    /(psych|sci.)/.test(window.location.hostname) ? 'psych' :
    /(veg|xxx)/.test(window.location.hostname) ? 'veg' :
    /(green|locx|xxxx)/.test(window.location.hostname) ? 'green' :
    /(vj.|demo.|vijay.)/.test(dbStorageKey) ? 'vj' :  
        'esg';

const clientStorageKey = getFromStorage('clientKey');
const clientUrlKey = /(xxx|local|j.alpha)/.test(window.location.hostname) ? 'ESG' :
    /(esg|xxxx|x.)/.test(window.location.hostname) ? 'ESG' :
    /(equality|localx)/.test(window.location.hostname) ? 'Equ' :
    /(sdg|locxx)/.test(window.location.hostname) ? 'SDG' :
    /(pope|frances)/.test(window.location.hostname) ? 'Pope' :
    /(psych|sci.)/.test(window.location.hostname) ? 'Psych' :
    /(veg|xxx)/.test(window.location.hostname) ? 'Veg' :
    /(green|locx|xxxx)/.test(window.location.hostname) ? 'Green' :
    /(vj.|demo.|vijay.)/.test(dbStorageKey) ? 'VJ' :  
    /(demo)/.test(dbStorageKey) ? 'Demo' :  
        'ESG';

const emoji = /(sdg)/.test(window.location.hostname) ? '🇺🇳' :
        /(esg|impact)/.test(window.location.hostname) ? '🌎' :
        /(veg|plant)/.test(window.location.hostname) ? '🌱' :
        /(equ)/.test(window.location.hostname) ? '⚖️' :
        /(tax)/.test(window.location.hostname) ? '🇺🇸' :
        /(local)/.test(window.location.hostname) ? '💻' :
        /(index)/.test(window.location.hostname) ? '📐' :                        
        /(test)/.test(window.location.hostname) ? '🏳️' :
            '😀';


const ESG = (clientStorageKey === 'ESG' || (clientStorageKey ? false : (clientUrlKey === 'ESG')));
const Env = (clientStorageKey === 'ESG' || (clientStorageKey ? false : (clientUrlKey === 'Env')));
const Soc = (clientStorageKey === 'Soc' || (clientStorageKey ? false : (clientUrlKey === 'Soc')));
const Gov = (clientStorageKey === 'Gov' || (clientStorageKey ? false : (clientUrlKey === 'Gov')));
const Equ = (clientStorageKey === 'Equ' || (clientStorageKey ? false : (clientUrlKey === 'Equ')));
const Pope = (clientStorageKey === 'Pope' || (clientStorageKey ? false : (clientUrlKey === 'Pope')));
const Psych = (clientStorageKey === 'Psych' || (clientStorageKey ? false : (clientUrlKey === 'Psych')));
const SDG = (clientStorageKey === 'SDG' || (clientStorageKey ? false : (clientUrlKey === 'SDG')));
const Veg = (clientStorageKey === 'Veg' || (clientStorageKey ? false : (clientUrlKey === 'Veg')));
const Green = (clientStorageKey === 'Green' || (clientStorageKey ? false : (clientUrlKey === 'Green')));
const VJ = (clientStorageKey === 'VJ' || (clientStorageKey ? false : (clientUrlKey === 'VJ')));
const Demo = (clientStorageKey === 'Demo' || (clientStorageKey ? false : (clientUrlKey === 'Demo')));


const colorESG = "#3357ff";
const colorEqu = "#ff3a21";
const colorSDG = "#5b92e5";
const colorPope = "#911eb4";
const colorPsych = "#911eb4";
const colorVeg= "#01A901";
const colorGreen = "#72ba48";
const colorVJ = "#72ba48";
const colorDemo = "#72ba48";

export const dataConfig = {
    codeVersion: '3.072101',
    showBenchmark2: /(customsdg|local|esg.impact)/.test(window.location.hostname),

    portfolioSize: 60,
    impactPointsCompanies: "20",

    emailTimeOut: /(instant|pop)/.test(window.location.hash) ? 500 :
        /(local|j.|custom)/.test(window.location.hostname) ? 60000 : 24000,
    geoDelay: 5000,

    isBot: isBot, // not used
    isTestDomain: isBot || isTestDomain,
    isHashEmail: isHashEmail,
    isHashOverride: isHashOverride,
    skipEmailNotifications: isHashEmail ? false : (isBot || isTestDomain),
    skipLogRocket:  isBot || isTestDomain,
    
    // not yet implemented:
    listIpDev: [
        {ip: '135.180.54.54'},  // 2022-07
        {ip: '135.180.54.54'},  // 2022-07
        {ip: '192.184.250.137'},  // eero 6E
        {ip: '135.180.54.54'},  // 2022-07
        {ip: '135.180.55.160'}, // sonic
        {ip: '135.180.53.171'},
        {ip: '135.180.53.137'},
        {ip: '73.223.150.99'}, // gough
        {ip: '76.20.41.67'}, // james ?
        {ip: '98.244.7.105'}, // JamesPV: 2022-07
        {ip: '73.162.223.14'}, // clay
        {ip: '73.223.150.99'},  // Gough-Alnita
        {ip: '76.20.41.67'},    // -James2021
        {ip: '76.79.92.187'},
        {ip: '76.20.41.67'},
        {ip: '99.120.0.112'}, // Jackson2021
        {ip: '73.162.223.14x'},
        {ip: '73.162.223.14x'},
        {ip: '73.222.170.193'},   // Alhambra?
    ],

    
    dbUrlKey: dbUrlKey,
    dbStorageKey: dbStorageKey,
    dbKey: dbStorageKey || dbUrlKey, // we also use this when: !getFromStorage('db')

    clientUrlKey: clientUrlKey,
    clientStorageKey: clientStorageKey,
    clientKey: clientStorageKey || clientUrlKey, // we also use this when: !getFromStorage('clientKey')


    apiUrl: "https://www.impactlabs.app/",
    geoPluginUrl: "https://ssl.geoplugin.net/javascript.gp?k=4cc0e19c1c358733",
    websiteUrl: window.location.hostname.replace('www.', ''),
    navPlatform: navigator.platform.replace('MacIntel', 'Mac').replace('Win32', 'Win').replace('Linux arm', 'LnxA').replace('Linux x86_64', 'Lnx'),

    ESG: ESG,
    Env: Env,
    Soc: Soc,
    Gov: Gov,
    Equ: Equ,
    Pope: Pope,
    Psych: Psych,
    SDG: SDG,
    Veg: Veg,
    Green: Green,
    VJ: VJ,
    Demo: Demo,

    colorESG: colorESG,
    colorEnv: colorVeg,
    colorSoc: colorEqu,
    colorEqu: colorEqu,
    colorGov: colorESG,
    colorSDG: colorSDG,
    colorPope: colorPope,
    colorPsych: colorPsych,
    colorVeg: colorVeg,
    colorGreen: colorGreen,
    colorVJ: colorVJ,
    colorDemo: colorDemo,
    colorButton: 'transparent',
    colorFont: '#545454',

    emoji: ((window.location.hostname === 'impactlabs.com' ? '♻️' :
        window.location.hostname === 'impactlabs.com' ? '🔵' : '')
        + emoji),

    impactTitle: 'IMPACT Labs',
    impactTitleSmall: /(veg|xxx)/.test(window.location.hostname) ? '.com' :
        '',  // <small>
    impactTitleSub: (Veg|SDG|Equ|Psych) ? '' : 
        VJ ? 'folio' :
        Pope ? '✝' :
        'x', //subscript text <sub>
    impactSubtitle: '',
    impactSubtitle2: 'Direct Indexing',
    impactSubtitle3: 'Ultra-Tailored',
    impactFooter: '', //'ESG: environment, social & corporate governance factors',
    impactEmoji: '', //'🌎  ⚖️  🛡',


    appTitle: Veg  ? 'Green investing, redefined℠' :
        SDG ? (emojiSDG + ' SDGs are just the beginning') :
        VJ  ? 'Sustainable investing: redefined' :
            'Custom Direct Indexing',

    appSubtitle: Veg ? 'Sustainable investing, optimized by IMPACT' :
        SDG ? 'What are your sustainable development goals?' :
            'DirX.ai: The Power of Choice',
    appSubtitleSub: SDG ? '' : 
        VJ ? 'v' :
        '', //subscript text

	logoTextValue: Veg ? 'Vegemize 🌱' :
		SDG ? (emojiSDG + ' SDG') :
        Pope ? 'IMPACT' :
        Equ ? 'Invest for Equality' :
        Psych ? 'Psych-free ' :
        (Demo || VJ) ? '🌅 IMPACT' :
        Psych ? 'Psych-free ' :
		'🌎 IMPACT',
    logoTextValueSmall:  SDG ? '' : '', // smaller text
    logoTextValueSub: (Veg|Equ|Psych|SDG) ? '' : 
        VJ ? 'folio' :
        Pope ? '✝' :
        'x', //subscript text
    logoTextValue2: (Veg|Equ|Psych) ? '' : 
        VJ ? '' :
        Pope ? '' :
        ' Customizer', //subscript text


    logoImageRef: (Equ) ? 'https://d3e1j90z5g3six.cloudfront.net/images/icons/png/genderEq_400x.png' : 
        (Psych) ? 'https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_89995_unchained.png' : '',


	siteColor:  (Veg) ? colorVeg:
        (Green || Demo || VJ) ? colorGreen:
		SDG ? colorSDG :
		Pope ? colorPope :
		Equ ? colorEqu :
		Psych ? colorPsych :
		ESG ? colorESG : colorESG,

    colorBenchmark1: '#243EB6',
    colorBenchmark2: '#8a9fff',
    colorYourIndex:  (Veg) ? colorVeg :
        (Green || Demo || VJ) ? colorGreen :
        SDG ? colorESG :
        Pope ? colorPope :
        Equ ? colorEqu :
        Psych ? colorPsych :
        ESG ? colorESG : colorESG,

    allowNewerEmoji: mobileOrMacOSX_10_14,
    emojiSDG: mobileOrMacOSX_10_14 ? '🇺🇳' : '🌍',
    emojiSDG2: mobileOrMacOSX_10_14 ? '🇺🇳' : ' ',
    emojiSDGdot: mobileOrMacOSX_10_14 ? '🇺🇳' : 'SDG·',


}
console.log('⚙️ dbStorageKey: ', dbStorageKey);
console.log('⚙️ dbUrlKey: ', dbUrlKey);
console.log('⚙️ dataConfig.dbKey: ', dataConfig.dbKey);
console.log('⚙️ clientUrlKey: ', clientUrlKey);
console.log('⚙️ clientStorageKey: ', clientStorageKey);
console.log('⚙️ dataConfig.clientKey: ', dataConfig.clientKey);
console.log('⚙️ dataConfig.emojiSDG: ', emojiSDG);
console.log('⚙️ emojiSDG: ', emojiSDG);
console.log('⚙️ dataConfig.emoji: ', dataConfig.emoji, emoji);