import axios from "axios";
import { dataConfig } from "config/dataConfig";
import { getFromStorage, setInStorage } from "localStorageService";

export const config = {
    apiUrl: dataConfig.apiUrl
};

const phpUrl = 'geoESG.php';
const makeRequest = (url, extraParams) => {
    const options = {
        ...extraParams,
        headers: {
            ...(extraParams ? extraParams.headers : {})
        }
    };
    return new Promise((resolve, reject) => {
        fetch(url, options)
            .then(response => {
                if (response.ok) {
                    return response.json().catch(() => "");
                }
                reject(response);
            })
            .then(response => {
                return resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
};

export const postData = (resource, payload) =>
    makeRequest(config.apiUrl + resource, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(payload)
    });

export const postPhpData = (resource, payload) =>
    makeRequest(resource, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(payload)
    });
export const putData = (resource, payload) => {
    return makeRequest(config.apiUrl + resource, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(payload)
    });
};

export const getData = resource => makeRequest(config.apiUrl + resource);

export const getPortfolio = data => postData("impact_points", data);

// const impactPontsAddRemove = "http://ec2-52-39-198-104.us-west-2.compute.amazonaws.com/api/portfolio/portfolio-engine"

export const setImpactPoint = data =>
    postData("api/portfolio/portfolio-engine", data);

export const getTickers = data => {
    // manual update:
    // data.included = ["AAPL", "IBM", "T", "TSLA", "F", "COF", "V", "FB", "C", "WFC"]
    // data.size = data.included.length; 

    var apiCount = getFromStorage("apiCount") || 0;
    apiCount = parseInt(apiCount) + 1;
    setInStorage("apiCount", apiCount);

    return postData("api/portfolio/portfolio-engine", data);

}

export const sendEmailNotification = data => {
    if (dataConfig.skipEmailNotifications) {
        if (/(over|email)/.test(window.location.hash)) {
            console.log('📩 email... userOverride!! /(over|email)/.test(window.location.hash)');
        } else {
            console.log('📩 🌎 ⛔️ sendEmailNotification-skip:', dataConfig.skipEmailNotifications, dataConfig.websiteUrl);
            console.log('📩 ⛔️ bot/test/skipEmail/isHashOverride:');
            console.log('📩 ⛔️', dataConfig.isBot, dataConfig.isTestDomain, dataConfig.skipEmailNotifications, dataConfig.isHashOverride);
            return;
        }
    }
    console.log("📩 1️⃣ 🌎 just before post", data);
    return axios
        .post(phpUrl, data)
        .then(function(response) {
            console.log("📩 🌎 ...?", response);
        })
        .catch(function(error) {
            console.log('📩 🌎 ❌ sendEmailNotification ❌❌❌ ERROR:', error);
        });
};

export const sendUserPreferences = data => {
    if (dataConfig.skipEmailNotifications) {
        console.log('📩 ⛔️ sendUserPreferences skipEmailNotifications:', dataConfig.skipEmailNotifications, dataConfig.websiteUrl);
        if (/(over|email)/.test(window.location.hash)) {
            console.log('📩 email... userOverride!! /(over|email)/.test(window.location.hash)');
        } else {
            console.log('📩 ⛔️ sendUserPreferences:', dataConfig.skipEmailNotifications, dataConfig.websiteUrl);
            console.log('📩 ⛔️ bot/test/skipEmail/isHashOverride:');
            console.log('📩 ⛔️', dataConfig.isBot, dataConfig.isTestDomain, dataConfig.skipEmailNotifications, dataConfig.isHashOverride);
            return;
        }
    } 
    return axios
			.post(phpUrl, data)
			.then(function(response) {
				console.log("📩 ✅ ♻️ ✅ sendUserPref:", response);
			})
			.catch(function(error) {
                console.log('📩 ❌ sendUserPref ❌❌ ERROR:', error);
			});
};

export const submitUserEmail = data => {
    if (dataConfig.skipEmailNotifications) {
        console.log('📩 ⛔️ submitUserEmail skipEmailNotifications:', dataConfig.skipEmailNotifications, dataConfig.websiteUrl);
        if (/(over|email)/.test(window.location.hash)) {
            console.log('📩 email... userOverride!! /(over|email)/.test(window.location.hash)');
        } else {
            console.log('📩 ⛔️ submitUserEmail:', dataConfig.skipEmailNotifications, dataConfig.websiteUrl);
            console.log('📩 ⛔️ bot/test/skipEmail/isHashOverride:');
            console.log('📩 ⛔️', dataConfig.isBot, dataConfig.isTestDomain, dataConfig.skipEmailNotifications, dataConfig.isHashOverride);
            return;
        }
    } 
	return axios
			.post(phpUrl, data)
			.then(function(response) {
					console.log('📩 ✅ ✅ submit success:', response);
			})
			.catch(function(error) {
					console.log('📩 ❌ submitUserEmail ❌ ERROR:', error);
			});
};

export const getGeoLocation = async () => {
	console.log("🌎 🍅 🍅 getGeoL...");
    return axios
			.get(dataConfig.geoPluginUrl)
			.then(function(response) {
				console.log("🌎 🍅 🍅 🍅 getGeoL:", response);
			})
			.catch(function(error) {
                console.log('🌎 🍅 🍅 🍅 ❌ ❌ geo location error:', error);
			});
};

